.root-981F
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.text-981F
  font-size: 5.5rem
  line-height: 5.5rem
  font-weight: 500
  margin-left: 0.5rem
  text-decoration: underline
  background: -webkit-linear-gradient(70deg, #441660, #AF159E, #FB2361, #f5af19)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  border-bottom: 10px solid #AF159E


.rotated-981F
  transform: rotate(180deg)
  writing-mode: vertical-rl
  font-size: 10rem
  line-height: 10rem
  font-weight: 500
  background: -webkit-linear-gradient(70deg, #441660, #AF159E, #FB2361)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent