.root-77E5
  width: 18rem
  height: 4rem
  filter: drop-shadow(0 0 32px rgb(0 0 0 / 25%))
  margin: 16px
  cursor: pointer

  a
    text-decoration: none

.clip-77E5
  width: 18rem
  height: 4rem
  background: -webkit-linear-gradient(70deg, #AF159E, #FB2361)
  //clip-path: polygon(0% 100%, 0% 50%, 10% 0, 20% 0, 10% 100%, 15% 100%, 25% 0, 30% 0, 20% 100%, 25% 100%, 35% 0%, 100% 0%, 100% 50%, 90% 100%)
  clip-path: polygon(0% 100%, 0% 50%, 10% 0, 35% 0%, 100% 0%, 100% 50%, 90% 100%)
  color: white
  display: flex
  align-items: center
  justify-content: center

.text-77E5
  //padding-left: 28%
  font-size: 2.5rem
  line-height:  2.5rem
  font-weight: 600
  text-align: center
  vertical-align: center
  text-transform: uppercase