.root-632A
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  width: 98vw
  overflow: hidden

.container-632A
  display: flex
  flex-direction: row

.text-632A
  padding: 4px 12px 4px 12px
  display: flex
  align-items: center
  justify-content: center
  color: white
  font-weight: 400
  font-size: 2rem
  line-height: 1rem
  width: 150px

@keyframes chevron-632A
  0%
    opacity: 0.75
  50%
    opacity: 0.75
  75%
    opacity: 1
  100%
    opacity: 0.75