.root-DBBB
  display: flex
  flex-direction: column
  background: -webkit-linear-gradient(70deg, #441660, #AF159E, #FB2361, #f5af19)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  font-family: 'Kanit', sans-serif
  overflow: hidden

.row-DBBB
  display: flex
  flex-direction: row

.underline-DBBB
  width: 10px
  margin: 0 0 0 0.7rem
  letter-spacing: -1px

.container-DBBB
  display: flex
  flex-direction: column
  justify-content: space-between

.large-DBBB
  font-size: 16rem
  line-height: 12rem
  font-weight: 600
  margin-right: 2rem

.small-DBBB
  margin-top: 0.5rem
  font-size: 7rem
  line-height: 5.7rem
  display: flex
  flex-direction: column
  justify-content: space-between
  font-weight: 400