$speed: 500ms

.content-5150
  width: 100vw
  height: 100vh
  overflow: auto

.container-5150
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100vw
  height: 100vh
  z-index: 100
  overflow: hidden

.left-curtain-5150, .right-curtain-5150
  position: absolute
  height: 100%
  width: 200%
  background: #1d1d1d
  transition: left $speed ease, right $speed ease
  transform: skew(-43deg)

.left-curtain-5150
  left: -300%

.right-curtain-5150
  right: -300%

.curtain-5150-enter-active, .curtain-5150-enter-done, .curtain-5150-appear
  .left-curtain-5150
    left: -149%

  .right-curtain-5150
    right: -149%

@keyframes load-in
  0%
    opacity: 0

  50%
    transform: translateZ(-800px) rotateX(90deg)
    opacity: 0

  55%
    opacity: 1

  100%
    transform: translateZ(0) rotateX(0)
    opacity: 1

.loading-container-5150
  z-index: 10
  color: white
  position: absolute
  top: 50%
  right: 50%
  transform: translate(50%, -50%)
  width: 200px
  height: 200px
  display: flex
  align-items: center
  justify-content: center

.loading-animation-5150
  opacity: 0

.curtain-5150-enter-active, .curtain-5150-enter-done, .curtain-5150-appear-active, .curtain-5150-appear-done
  .loading-animation-5150
    animation: load-in 1s
    opacity: 1