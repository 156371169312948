@keyframes bar-4E02
  0%, 40%, 100%
    transform: scaleY(0.05)
  20%
    transform: scaleY(1.0)


.text-4E02
  display: flex
  text-transform: uppercase
  > div
    width: 1rem
    text-align: center

.bars-4E02
  display: flex
  > div
    width: 0.8rem
    margin: 0 0.1rem 0 0.1rem
    height: 4rem
    transform: scaleY(0.05)