.root-7165
  background-color: white
  color: black
  width: 100%
  min-height: 100vh

.section-7165
  min-height: 99vh
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center

.status-7165
  left: 0px
  bottom:  0px
  width: 0px
  height: 0px
  transform: rotate(270deg)
  padding: 1rem